import React, { FC } from "react";
import PlaceHolderImage from "../../../images/gal-placeholder-circle.svg";
import PlaceHolderImageALT from "../../../images/guy-placeholder-circle.svg";
import checkmark from "../../../images/check.svg";
import { Kol } from "../../../types";

type HCPOptionProps = {
  data: Kol;
  [key: string]: any;
};

function kolName(kol?: Kol): string {
  return kol ? `${kol.firstName} ${kol.lastName}, ${kol.designation}` : "";
}

export const HCPOption: FC<any> = ({
  data,
  ref,
  innerProps,
  ...rest
}: HCPOptionProps) => {
  const currentlySelected =
    rest?.selectProps?.value?.name === kolName(data)
      ? "currently-selected-in-list"
      : "";

  return (
    <div
      className={`form-input--select__container form-input--select__item ${currentlySelected}`}
      ref={ref}
      {...innerProps}
    >
      {data.photo && (
        <>
          {currentlySelected && <img className="checkmark" src={checkmark} />}
          <img
            className="form-input--select__image"
            alt={data.photo.alternativeText || `Photo of ${kolName(data)}`}
            src={data.photo.localFile.publicURL}
          />
        </>
      )}
      <div className="form-input--select__text">
        <p className="form-input--select__name">{kolName(data)}</p>
        <p className="form-input--select__location">{data.location}</p>
      </div>
    </div>
  );
};

type HCPSelectedOptionProps = {
  data: Kol;
  [key: string]: any;
};

export const HCPSelectedOption: FC<HCPSelectedOptionProps> = ({
  data,
  ref,
  innerProps,
  ...rest
}) => {
  return (
    <div
      className="form-input--select__container currently-selected-displayed"
      ref={ref}
    >
      {data.photo && (
        <img
          className="form-input--select__image"
          alt={data.photo.alternativeText || `Photo of ${kolName(data)}`}
          src={data.photo.localFile.publicURL}
        />
      )}
      <div className="form-input--select__text">
        <p className="form-input--select__name">{kolName(data)}</p>
        <p className="form-input--select__location">{data.location}</p>
      </div>
    </div>
  );
};

export const HCPPlaceholder: FC<any> = ({ ref, innerProps }) => {
  return (
    <div
      className="form-input--select__container currently-selected-displayed"
      ref={ref}
      {...innerProps}
    >
      <img className="form-input--select__image" src={PlaceHolderImage} />
      <div className="form-input--select__text">
        <p className="form-input--select__name">Select your preferred expert</p>
      </div>
    </div>
  );
};

export const HCPPlaceholderALT: FC<any> = ({ ref, innerProps }) => {
  return (
    <div
      className="form-input--select__container currently-selected-displayed"
      ref={ref}
      {...innerProps}
    >
      <img className="form-input--select__image" src={PlaceHolderImageALT} />
      <div className="form-input--select__text">
        <p className="form-input--select__name">Select your preferred expert</p>
      </div>
    </div>
  );
};
