import React, { FC } from "react";
import SelectInput from "../Select";

import { InputContainer } from "../Input";

import clock from "../../../images/clock-icon.svg";

import "./styles.scss";

type TimePickerType = {
  name: string;
  errorMessage: string;
};

const TimePickerInput: FC<TimePickerType> = ({ name, errorMessage }) => {
  const times = [
    { label: "8:00 am", value: "8:00 am" },
    { label: "8:30 am", value: "8:30 am" },
    { label: "9:00 am", value: "9:00 am" },
    { label: "9:30 am", value: "9:30 am" },
    { label: "10:00 am", value: "10:00 am" },
    { label: "10:30 am", value: "10:30 am" },
    { label: "11:00 am", value: "11:00 am" },
    { label: "11:30 am", value: "11:30 am" },
    { label: "12:00 pm", value: "12:00 pm" },
    { label: "12:30 pm", value: "12:30 pm" },
    { label: "1:00 pm", value: "1:00 pm" },
    { label: "1:30 pm", value: "1:30 pm" },
    { label: "2:00 pm", value: "2:00 pm" },
    { label: "2:30 pm", value: "2:30 pm" },
    { label: "3:00 pm", value: "3:00 pm" },
    { label: "3:30 pm", value: "3:30 pm" },
    { label: "4:00 pm", value: "4:00 pm" },
    { label: "4:30 pm", value: "4:30 pm" },
    { label: "5:00 pm", value: "5:00 pm" },
    { label: "5:30 pm", value: "5:30 pm" },
    { label: "6:00 pm", value: "6:00 pm" },
    { label: "6:30 pm", value: "6:30 pm" },
  ];

  return (
    <InputContainer errorMessage={errorMessage} name={name} label="">
      <SelectInput
        placeHolder="00:00 am/pm"
        styles={timeSelectStyles}
        name={name}
        options={times}
      />
    </InputContainer>
  );
};

const timeSelectStyles = {
  container: (provided) => ({
    ...provided,
    backgroundImage: "url(" + clock + ")",
    backgroundSize: "25px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "10px center",
  }),
  control: (provided) => ({
    ...provided,
    background: "transparent",
    borderRadius: 0,
    border: "1px solid #979797",
    paddingLeft: "32px",
    height: "42px",
    cursor: "pointer",
  }),
};

export default TimePickerInput;
