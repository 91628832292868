import React, { FC, useContext } from "react";
import { FormsContext, FormsContextProviderType } from "../FormsContext";

import "./styles.scss";

type CheckboxType = {
  name: string;
};

const Checkbox: FC<CheckboxType> = ({ name }) => {
  const formContext: FormsContextProviderType = useContext(FormsContext);
  const formData = formContext.formData;
  const value = (formData && formData[name]) || "false";

  const isChecked = value === "true";

  return (
    <div
      className="form-fields--input-checkbox"
      onClick={() => {
        formContext.updateFormData(name, isChecked ? "false" : "true");
      }}
    >
      <div
        className={`form-fields--input-checkbox__indicator${
          isChecked ? " checked" : ""
        }`}
      ></div>
    </div>
  );
};

export default Checkbox;
