import React, { FC } from "react";
import Checkbox from "../Checkbox";
import { InputContainer } from "../Input";

import "./styles.scss";

const ConsentCheckbox: FC<{ name: string }> = ({ name }) => {
  return (
    <InputContainer label="" name={name}>
      <div className="form-fields--consent-checkbox__container">
        <Checkbox name={name} />
        <p className="form-fields--consent-checkbox__content">
          <strong>I would like to sign up for email updates from GBT.</strong>
        </p>
      </div>
    </InputContainer>
  );
};

export default ConsentCheckbox;
