import React, { FC, useContext, useEffect, useState } from "react";

import {
  FormsContext,
  FormsContextProviderType,
} from "../../components/Forms/FormsContext";

import { RadioGroup, RadioInput } from "../../components/Forms/Radio";
import { InputGroup } from "../../components/Forms/Input";
import DatePicker from "../../components/Forms/DatePicker";
import TimePicker from "../../components/Forms/TimePicker";
import PrivacyPolicy from "../../components/Forms/PrivacyPolicy";
import SubmitButton from "../../components/Forms/SubmitButton";
import InfoBubbleLink from "../../components/Forms/InfoBubbleLink";
import HCPDropdown from "../../components/Forms/HCPDropdown";
import MarketingConsent from "../../components/Forms/MarketingConsent";

import { graphql, PageProps } from "gatsby";

import { Kol, StrapiQueryData } from "../../types";
import FormContainer from "../../components/Forms/FormContainer";
import { Page } from "../../components/Page";

import { eventTracker, pageTracker } from "../../tracker";

import "./styles.scss";

export const query = graphql`
  query MyQuery2 {
    allStrapiKol(sort: { fields: lastName, order: ASC }) {
      nodes {
        bio
        firstName
        lastName
        designation
        title
        location
        photo {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;

const SessionDetails: FC<PageProps<StrapiQueryData>> = ({ data }) => {
  React.useEffect(() => {
    pageTracker({
      pageTitle: "Request KOL > Session Details (Step 3/4)",
      pageSubject: "Meeting Request",
    });

    eventTracker({
      eventAction: "Viewed Step",
      eventCategory: "Request KOL Form",
      eventLabel: "Step 3/4 - Session Details",
    });
  }, []);

  return (
    <Page>
      <FormContainer>
        <SessionDetailsMarkup data={data} />
      </FormContainer>
    </Page>
  );
};

type SessionDetailsType = {
  data: {
    allStrapiKol: {
      nodes: Kol[];
    };
  };
};

const SessionDetailsMarkup: FC<SessionDetailsType> = ({ data }) => {
  const formContext: FormsContextProviderType = useContext(FormsContext);
  const formData = formContext.formData;

  const KOLData = data.allStrapiKol.nodes;

  const showPrefHCPExInit = formData && formData["hasPreferredExpert"];

  const [showPreferredHCPExtras, setShowPreferredHCPExtras] = useState(
    showPrefHCPExInit
  );

  return (
    <div className="form-page--session-details-page">
      <h1 className="form-fields--headline">Let’s plan your online session</h1>

      <p className="form-fields--note body-text--small">*Required fields</p>

      <div className="form-fields--container">
        <RadioGroup
          name="presentationTopic"
          label="What type of session are you interested in?*"
          errorMessage="Please select a session."
        >
          <RadioInput
            label="Mechanism of Action and Clinical Trial Data for Oxbryta"
            value="Mechanism of Action and Clinical Trial Data for Oxbryta"
            id="session-interest-1"
            name="presentationTopic"
            moreInfo={
              <InfoBubbleLink
                trackingLabel="Mechanism of Action and Clinical Trial Data for Oxbryta"
                label="More Info"
                text="Get a better understanding of how Oxbryta works to inhibit the polymerization of hemoglobin S (HbS) in patients with sickle cell disease.<sup>1,2</sup>"
              />
            }
          />
          <RadioInput
            label="Treating the Root Causes of Sickle Cell Disease in Adult Patients"
            value="Treating the Root Causes of Sickle Cell Disease in Adult Patients"
            id="session-interest-2"
            name="presentationTopic"
            moreInfo={
              <InfoBubbleLink
                trackingLabel="Treating the Root Causes of Sickle Cell Disease in Adult Patients"
                label="More Info"
                text="This presentation explores clinical trial data of Oxbryta and covers efficacy and safety in adult patients."
              />
            }
          />
          <RadioInput
            label="Treating the Root Causes of Sickle Cell Disease in Pediatric Patients"
            value="Treating the Root Causes of Sickle Cell Disease in Pediatric Patients"
            name="presentationTopic"
            id="session-interest-3"
            moreInfo={
              <InfoBubbleLink
                trackingLabel="Treating the Root Causes of Sickle Cell Disease in Pediatric Patients"
                label="More Info"
                text="This presentation explores clinical trial data of Oxbryta and covers efficacy and safety in pediatric patients."
              />
            }
          />
          <RadioInput
            label="An interactive Oxbryta presentation and Q&amp;A"
            value="An interactive Oxbryta presentation and Q&amp;A"
            id="session-interest-4"
            name="presentationTopic"
            moreInfo={
              <InfoBubbleLink
                trackingLabel="An interactive Oxbryta presentation and Q&amp;A"
                label="More Info"
                text="You’ll receive an overview about Oxbryta and get your questions answered about how it works to treat sickle cell disease."
              />
            }
          />
        </RadioGroup>

        <RadioGroup
          inline
          name="hasPreferredExpert"
          label="Do you have a preferred medical expert?*"
          errorMessage="Please select a preference."
        >
          <RadioInput
            label="Yes"
            value="true"
            id="preferred-radio-yes"
            name="hasPreferredExpert"
            onSelect={() => {
              setShowPreferredHCPExtras(true);
            }}
          />
          <RadioInput
            label="No"
            value="false"
            id="preferred-radio-no"
            name="hasPreferredExpert"
            onSelect={() => {
              setShowPreferredHCPExtras(false);
            }}
          />
        </RadioGroup>

        {showPreferredHCPExtras && <PreferredHCPExtras data={KOLData} />}

        <InputGroup
          label="Select your preferred date and time.*"
          subLabel="Sessions will be scheduled in the time zone where you currently practice."
        >
          <DatePicker
            name="preferredDate"
            errorMessage="Please select a preferred date."
          />
          <TimePicker
            name="preferredTime"
            errorMessage="Please select a preferred time."
          />
        </InputGroup>

        <InputGroup
          label="Select an alternate date and time.*"
          subLabel="Your session will be scheduled at this time if your preferred date and time are unavailable."
        >
          <DatePicker
            name="alternateDate"
            errorMessage="Please select an alternate date."
          />
          <TimePicker
            name="alternateTime"
            errorMessage="Please select an alternate time."
          />
        </InputGroup>

        <MarketingConsent name="optInMarketing" />
        <PrivacyPolicy />
      </div>

      <SubmitButton
        text="Request My Session"
        analytics={{
          subject: "Meeting Request",
          label: "Request KOL - Step 4/4 - Request my Session",
          action: "Clicked",
          category: "Internal Links",
        }}
      />
    </div>
  );
};

export default SessionDetails;

const PreferredHCPExtras: React.FC<{ data: Kol[] }> = ({ data }) => {
  const preferredHCPOptions = data;

  return (
    <div className="form-fields--extra-items">
      <HCPDropdown
        name="preferredExpert"
        label="Select your preferred medical expert.*"
        errorMessage="Please select a preferred medical expert."
        options={preferredHCPOptions}
      />
      <HCPDropdown
        errorMessage="Please select an alternate medical expert."
        name="alternateExpert"
        label="Select an alternate medical expert.*"
        subLabel="Choose a backup in case the preferred medical expert is unavailable."
        alternativePlaceholder
        options={preferredHCPOptions}
      />
      <p className="form-fields--note body-text--small">
        All healthcare professionals are paid for their participation. We'll
        reach out to you if there are any scheduling conflicts.
      </p>
    </div>
  );
};
