import React, { FC, useContext } from "react";
import { FormsContext, FormsContextProviderType } from "../FormsContext";

import { InputContainer } from "../Input";

// https://reactdatepicker.com/
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./styles.scss";

type DatePickerType = {
  name: string;
  errorMessage: string;
};

const DatePickerInput: FC<DatePickerType> = ({ name, errorMessage }) => {
  const formContext: FormsContextProviderType = useContext(FormsContext);
  const formData = formContext.formData;
  const date = (formData && formData[name]) || null;

  let minDate = new Date();
  let offset = 3;

  switch (minDate.getDay()) {
    // Skips weekends when calulating 3 business day lead time
    case 3: // Wednesday
    case 4: // Thursday
    case 5: // Friday
      offset += 2;
      break;

    case 6: // Saturday
      offset += 1;
      break;
  }

  minDate.setDate(minDate.getDate() + offset);

  return (
    <InputContainer errorMessage={errorMessage} name={name} label="">
      <DatePicker
        selected={date && new Date(date)}
        placeholderText="MM/DD/YYYY"
        dateFormat="M/dd/yyyy"
        minDate={minDate}
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
        onChange={(date) => {
          formContext.updateFormData(
            name,
            date.toString().split("00:00:00")[0].trim()
          );
        }}
      />
    </InputContainer>
  );
};

export default DatePickerInput;
