import React, { FC } from "react";
import { Kol } from "../../../types";

import SelectInput from "../Select";
import {
  HCPOption,
  HCPPlaceholder,
  HCPSelectedOption,
  HCPPlaceholderALT,
} from "./HCPOption";

type options = {
  value: string;
  label: string;
};

type HCPDropdownType = {
  options: options[] | Kol[];
  name: string;
  errorMessage: string;
  label?: string;
  subLabel?: string;
  alternativePlaceholder?: boolean;
};

const HCPDropdown: FC<HCPDropdownType> = ({
  options,
  label,
  name,
  subLabel,
  alternativePlaceholder,
  errorMessage,
}) => {
  // https://react-select.com/styles#provided-styles-and-state

  const customComponents = {
    Option: HCPOption,
    SingleValue: HCPSelectedOption,
    Placeholder: alternativePlaceholder ? HCPPlaceholderALT : HCPPlaceholder,
  };

  const styles = {
    menu: (provided) => ({
      ...provided,
      top: "70px",
      borderRadius: "0px",
      background: "white",
    }),
    control: (provided) => ({
      ...provided,
      height: "auto",
      borderRadius: "0px",
      background: "transparent",
    }),
  };

  return (
    <SelectInput
      label={label}
      subLabel={subLabel}
      name={name}
      options={options}
      customComponents={customComponents}
      styles={styles}
      errorMessage={errorMessage}
    />
  );
};

export default HCPDropdown;
