import React, { FC } from "react";

import "./styles.scss";

const PrivacyPolicy: FC = () => {
  return (
    <>
      <h4 style={{ color: "black", fontSize: "15px" }}>
        <strong> Terms and Conditions</strong>
      </h4>
      <div className="form-fields--consent-checkbox__container">
        <p className="body-text--small">
          By requesting a meeting, you agree to allow GBT or its agents to
          contact you for the purposes of scheduling a meeting and any follow-up
          conversations. GBT will not sell or share this information with third
          parties for marketing purposes and will always provide you with the
          opportunity to change your advertising preferences. For additional
          information about how GBT handles your information, please see our{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.gbt.com/privacy-policy/"
            data-analytics-event-action="Followed"
            data-analytics-event-subject="Meeting Request"
            data-analytics-event-category="External Sites"
          >
            Privacy Policy
          </a>
          , which you are agreeing to by using this website.
        </p>
      </div>
      <div className="form-fields--consent-checkbox__container">
        <p className="body-text--small">
          By signing up for email updates, you agree to allow GBT and its agents
          to collect the information provided and to be contacted by GBT and its
          agents in the future regarding products and related disease education.
          GBT will not sell, rent, or otherwise distribute your name and any
          personally identifiable information outside of GBT and its agents. GBT
          will only use your information in accordance with its{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.gbt.com/privacy-policy/"
            data-analytics-event-action="Followed"
            data-analytics-event-subject="Meeting Request"
            data-analytics-event-category="External Sites"
          >
            Privacy Policy
          </a>
          .
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
