import React, { FC, PropsWithChildren, ReactElement, useContext } from "react";
import { FormsContext, FormsContextProviderType } from "../FormsContext";
import { InputContainer } from "../Input";

import "./styles.scss";

type RadioGroupType = {
  children: PropsWithChildren<{}>;
  label: string;
  name: string;
  errorMessage: string;
  inline?: boolean;
};

export const RadioGroup: FC<RadioGroupType> = ({
  children,
  inline,
  label,
  name,
  errorMessage,
}) => {
  const inlineClass = inline ? "inline" : "";

  return (
    <InputContainer
      name={name}
      label={label}
      className="form-input--radio-group-container"
      errorMessage={errorMessage}
    >
      <div className={`form-input--radio-group-inputs ${inlineClass}`}>
        {React.Children.map(children, (child) => {
          if (inline) {
            return <span className="form-input--radio">{child}</span>;
          }
          return <div className="form-input--radio">{child}</div>;
        })}
      </div>
    </InputContainer>
  );
};

type RadioInputType = {
  name: string;
  label: string;
  id: string;
  moreInfo?: ReactElement;
  onSelect?: () => void;
  value: string | boolean;
};

export const RadioInput: FC<RadioInputType> = ({
  name,
  label,
  id,
  moreInfo,
  onSelect,
  value,
}) => {
  const formContext: FormsContextProviderType = useContext(FormsContext);
  const formData = formContext.formData;
  const checked = (formData && formData[name] === value) || false;

  return (
    <label className="form-input--radio-label" htmlFor={id}>
      <input
        id={id}
        name={name}
        type="radio"
        value={value.toString()}
        checked={checked}
        className="form-input--radio-input"
        onChange={(e) => {
          if (onSelect) onSelect();
          formContext.updateFormData(name, value);
        }}
      />
      <div className="form-input--radio-ui__radio">
        <div className="form-input--radio-ui__dot"></div>
      </div>
      <div className="form-input--radio-label-text">
        {label}&nbsp;&nbsp;
        {moreInfo}
      </div>
    </label>
  );
};
